/* ================================= Blog Section Css Start Here ============================= */ 
.blog-item {    
    border-radius: 8px;
    overflow: hidden;
    height: 100%;
    transition: 0.3s;
    background-color: hsl(var(--section-bg));
    &:hover {
        box-shadow: var(--box-shadow);
        .blog-item {
            
            &__thumb {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
    &__thumb {
        overflow: hidden;
        border-radius: 8px 8px 0px 0px;
        max-height: 300px;
        &-link {
            width: 100%;
            height: 100%;
            img {
                transition: .2s linear;
            }
        }
    }
    &__content {
        background-color: hsl(var(--white)/.07); 
        padding: 24px;
        height: 100%;
        @include sm-screen {
            padding: 30px 20px;
        }
        @include xsm-screen {
            padding: 25px 15px;
        }
    }
    &__title {
        margin-top: 15px;
        margin-bottom: 10px;
        font-weight: 700;
        @include msm-screen {
            margin-top: 15px;
        }
        &-link {
            color: hsl(var(--heading-color));
            overflow:hidden;
            text-overflow:ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            &:hover {
                color: hsl(var(--base));
            }
        }
    }
    &__desc {
        margin-bottom: 20px;
        overflow:hidden;
        text-overflow:ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        @include sm-screen {
            margin-bottom: 15px;
        }
    }
}
.btn--simple {
    color: hsl(var(--heading-color));
    &:hover {
        color: hsl(var(--base));
    }
}

/* Text Border Bottom Animation Css Start */
.border-effect {
    display: inline!important;
    width: 100%;
    background-repeat: no-repeat;
    background-position-y: -2px;
    background-image: linear-gradient(transparent calc(100% - 1px),currentColor 1px);
    -webkit-transition: .6s cubic-bezier(.215,.61,.355,1);
    -o-transition: .6s cubic-bezier(.215,.61,.355,1);
    transition: .6s cubic-bezier(.215,.61,.355,1);
    background-size: 0 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.border-effect:hover {
    background-image: linear-gradient(transparent calc(100% - 1px),currentColor 1px);
    background-size: 100% 100%;

}
/* Text Border Bottom Animation Css Ends */
/* ================================= Blog Section Css End Here ================================= */