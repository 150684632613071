/* ================================= Dashboard Css Start =========================== */
.dashboard {
  position: relative;

  &__right {
    width: 100%;
    margin-left: 300px;
    background-color: #FAFAFA;

    @include md-screen {
      width: 100%;
      margin-left: 0;
    }
  }

  // ======================= Dashboard Header Start======================= 
  .dropdown {
    display: inline-block !important;

    @include sm-screen {}
  }

  .dashboard-header {
    padding: 0 24px;
    background-color: hsl(var(--white));
    border-bottom: 1px solid hsl(var(--border-color));

    @include xxl-screen {
      padding: 0 40px;
    }

    @include xl-screen {
      padding: 0 32px;
    }

    @include lg-screen {
      padding: 0 24px;
    }

    @include sm-screen {
      padding: 0 16px;
    }

    &__inner {
      padding: 15px 0;
      gap: 10px;

      @include msm-screen {
        display: block;
        text-align: center;
      }
    }

    &__right {
      gap: 25px;

      @include msm-screen {
        justify-content: center;
        margin-top: 10px;
      }
    }

    .bd-btn {
      padding: 10px 25px;
      background-color: hsl(var(--base)/.1);
      color: hsl(var(--base));
      border-radius: 5px;
      font-weight: 600;
      @extend .fs-16;

      &:hover {
        background-color: hsl(var(--base)/.2);
      }

      &:active {
        position: relative;
        top: 2px;
      }

      .icon {
        margin-right: 5px;

      }

      &.support {
        background-color: hsl(var(--primary)/.1);
        color: hsl(var(--primary));

        &:hover {
          background-color: hsl(var(--primary)/.2);
        }
      }

      &.logout {
        background-color: hsl(var(--danger)/.1);
        color: hsl(var(--danger));

        &:hover {
          background-color: hsl(var(--danger)/.2);
        }
      }
    }
  }

  /* User Info Css Start */
  .user-info {
    position: relative;
    text-align: center;

    &__button {
      position: relative;
      padding-right: 20px;

      &::before {
        position: absolute;
        content: "\f107";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        color: hsl(var(--black));
        @extend .fs-14;
        pointer-events: none;
      }
    }

    .user-info-dropdown {
      border-radius: 4px;
      overflow: hidden;
      transition: 0.25s linear;
      background-color: hsl(var(--white));
      box-shadow: 0px 5px 25px rgb(0 0 0 / 10%);
      width: 200px;
      position: absolute;
      right: 0;
      z-index: 9;
      top: 100%;
      margin-top: 15px;
      padding: 15px;
      transform: scale(.95);
      visibility: hidden;
      opacity: 0;

      &.show {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
      }

      @include md-screen {
        transform: unset !important;
        top: 43px !important;
      }

      &__item {
        &:last-child {
          .user-info-dropdown__link {
            border-bottom: 0 !important;
          }
        }
      }

      &__link {
        padding: 8px 16px !important;
        color: #5d7387 !important;
        margin-left: 0 !important;
        width: 100%;
        font-weight: 600;
        text-align: left;
        @extend .fs-15;
        border-radius: 5px;

        &.active {
          background-color: hsl(var(--base));
        }

        &:hover {
          background-color: hsl(var(--base)/.08);
          color: hsl(var(--base)) !important;
        }

        .icon {
          margin-right: 8px;
        }
      }
    }

    &__thumb {
      width: 40px;
      height: 40px;
      overflow: hidden;
      border-radius: 50px;
      border: 1.5px solid #F5A200;
    }

    &__name {
      margin-left: 10px;    
      font-weight: 700;
      color: hsl(var(--heading-color)); 
    }
  }

  /* User Info Css End */

  // ======================= Dashboard Header End ======================= 

  // ========== Dashboard Body Start ===============
  .dashboard-body {
    position: relative;
    padding: 45px 25px;
    min-height: 100vh;

    @include xxl-screen {
      padding: 56px 40px;
    }

    @include xl-screen {
      padding: 56px 32px;
    }

    @include lg-screen {
      padding: 40px 24px;
    }

    @include sm-screen {
      padding: 32px 16px;
    }

    &__bar {
      &-icon {
        color: hsl(var(--body-color));
        font-size: 1.5625rem; //25px
        cursor: pointer;
      }
    }
  }

  // Dashboard Card
  .dashboard-widget {
    padding: 24px;
    border-radius: 5px;
    background-color: hsl(var(--white));
    box-shadow: 0px 0px 20px rgb(231 231 231 / 40%);
    transition: .25s ease-in-out;
    border: 1.5px solid hsl(var(--base));
    @include xl-screen {
      padding: 16px;
    }

    @include sm-screen {
      padding: 16px;
    }

    &__icon {
      width: 50px;
      height: 50px;
      background-color: hsl(var(--base)/.08);
      color: hsl(var(--base));
      border-radius: 5px;
      font-size: 1.5rem;

      img {
        width: 32px;
      }

      @include msm-screen {
        width: 40px;
        height: 40px;
        @include fs-16;
      }
    }

    &__number {
      margin-bottom: 0px;

      &-amount {
        color: hsl(var(--base));
        font-size: 24px;
        font-weight: 800;
        font-family: var(--body-font);
      }

      &-value {
        font-weight: 600;
        font-size: 1rem;

        i {
          transform: rotate(315deg);
        }
      }
    }

    &__text {
      color: hsl(var(--body-color)/0.7);
      @extend .fs-18;
      margin-bottom: 5px;
      font-weight: 600;

      @include msm-screen {
        @include fs-13;
      }
    }

    &__left {
      margin-bottom: 20px;
    }
  }

  // ========== Dashboard Body End ==============
  /* ================================= Dashboard Css End =========================== */
}


.notifi__inner-header {
  padding: 15px 15px;
  padding-top: 5px;
  border-bottom: 1px solid hsl(var(--border-color));

  .caption {
    font-size: 0.75rem;
    font-weight: 700;
  }

  p {
    font-size: 0.75rem;
  }

}

.notifi__inner button i {
  color: #5b6e88;
  text-shadow: 1px 2px 5px rgba(0, 0, 0, 0.15);
  font-size: 1.5rem;
}

.navbar-notifi {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 15px 15px;
}

.dropdown-menu__item {
  border-bottom: 1px solid hsl(var(--border-color));
  width: 100%;

}

a.dropdown-menu__item:last-child {
  border-bottom: none;
}

.navbar-notifi:last-child {
  border-bottom: none;
}

.navbar-notifi__left {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
}

.navbar-notifi__right {
  width: calc(100% - 40px);
  padding-left: 10px;
}

.notifi__inner__footer .view-all-message {
  font-size: 0.75rem;
  display: block;
  padding: 15px 15px;
  text-align: center;
  color: #34495e;
  font-weight: 600;
}

.notifi__inner__footer {
  border-top: 1px solid hsl(var(--border-color));
}

.navbar-notifi__right .notifi__title {
  font-weight: 600;
  font-size: 0.875rem;
}

.dropdown-menu--md {
  min-width: 18rem;
}

.navbar-notifi__right .time {
  font-size: 0.875rem;
  color: hsl(var(--base));
}

.notifi__inner_body {
  max-height: 350px;
  overflow-y: scroll;
}

span.notifi-count-btn {
  position: absolute;
  content: '';
  background: #F50000;
  color: hsl(var(--white));
  width: 16px;
  height: 16px;
  font-size: 10px;
  border-radius: 50%;
  font-weight: 700;
  display: flex;
  top: -8px;
  align-items: center;
  justify-content: center;
  right: -4px;
  padding: 2px;
}

.edit-btn {
  color: hsl(var(--heading-color));
  padding: 10px;
  background-color: transparent;
  font-size: 20px;
}


// dashboard card design
.dashbaord-card {
  padding: 30px;
  background: hsl(var(--white));
  border-radius: 8px;
  border: 1.5px solid hsl(var(--border-color));

  @include msm-screen {
    padding: 20px;
  }

  &__header {
    margin-bottom: 30px;
  }

  .step-count {
    color: hsl(var(--base));
    font-size: 20px;
    font-weight: 700;
  }

  &-box {
    max-width: 600px;
    margin: 0 auto;
  }

  &__success {
    max-width: 500px;
    margin: 0 auto;
  }
}

.dashboard-form label {
  color: #88869A !important;
}

.add-box {
  background-color: hsl(var(--base)/.08);
  border-radius: 8px 8px 0px 0px;

  &__header {
    padding: 10px;
    border-bottom: 1px solid hsl(var(--body-color) / 0.2);
  }

  &__title {
    font-family: var(--body-font);

    @include xl-screen {
      font-size: 16px;
    }
  }

  &__body {
    padding: 30px 20px;
  }

  &__footer {
    .btn {
      border-radius: 0px 0px 8px 8px;
    }
  }
}

.new-form {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid hsl(var(--border-color));
}

// dashboard widgets style two design start here

.dashboard-widget-two {
  background: hsl(var(--white));
  padding: 45px 40px;
  border-radius: 8px;
  border: 1.5px solid hsl(var(--base));

  @include xxl-screen {
    padding: 30px;
  }

  @include lg-screen {
    padding: 20px;
  }

  &__thumb {
    width: 100px;
    height: 100px;

    @include lg-screen {
      width: 60px;
      height: 60px;
    }
  }

  &__content {
    padding-left: 40px;
    width: calc(100% - 100px);

    @include lg-screen {
      padding-left: 20px;
      width: calc(100% - 60px);
    }
  }

  &__text {
    font-size: 24px;
    font-weight: 600;
    color: hsl(var(--body-color)/0.7);
    margin-bottom: 15px;

    @include lg-screen {
      margin-bottom: 10px;
      font-size: 18px;
    }
  }

  &__number-amount {
    color: hsl(var(--base));
    font-size: 40px;
    font-weight: 700;
    font-family: var(--body-font);

    @include xxl-screen {
      font-size: 30px;
    }

    @include lg-screen {
      font-size: 24px;
    }

    @include msm-screen {
      font-size: 20px;
    }
  }
}

.dashboard-deposits {
  @include xsm-screen {
    display: block !important;
  }

  &__left {
    width: calc(100% - 175px);
    padding-right: 50px;

    @include xxl-screen {
      padding-right: 10px;
    }

    @include xsm-screen {
      width: 100%;
      padding-right: 0px;
    }
  }

  &__right-list {
    padding: 15px;
    background-color: hsl(var(--section-bg));
    border: 1px dashed hsl(var(--body-color)/ 0.1);
    border-radius: 8px;
    max-height: 192px;
    width: 175px;

    @include xsm-screen {
      width: 100%;
    }

    li {
      color: hsl(var(--base));
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0px;
      }

      p {
        color: #88869A;
        font-size: 14px;
        font-weight: 600;
      }

      span {
        font-size: 12px;
      }
    }
  }
}