
/* ============= Price Plan Start Here ======================= */
.price-item{
    padding: 32px;
    border-radius: 8px;
    height: 100%;
    transition: 0.3s;
    position: relative;
    &__badge{
        position: absolute;
        right: 0px;
        top: -23px;
        padding: 10px 20px;
        background-color: #F50058;
        color: hsl(var(--white));
        border-radius: 8px;
        font-weight: 700;
        font-family: var(--heading-font);
        font-size: 14px;
        @include md-screen{
            top: -16px;
            padding: 6px 15px;
        }
    }
    @include lg-screen{
        padding: 20px;
    }
    &:hover{
        box-shadow: var(--box-shadow);
    }
    &__header, &__body{
        margin-bottom: 32px;
        padding-bottom:32px;
        border-bottom: 1px solid hsl(var(--border-color));
        @include sm-screen{
            margin-bottom: 20px;
            padding-bottom: 20px;

        }
    }
    &__title{
        margin-bottom: 5px;
    }
    &__price{
        font-size: 56px;
        font-weight: 700;
        span{
            font-size: 2rem;
        }
        @include lg-screen{
            font-size: 45px;
        }
        @include sm-screen{
            font-size: 35px;
        }
    }
    &__duration{
        margin-bottom: 25px;
    }
    &__list{
        color: hsl(var(--heading-color));
        li{
            margin-bottom: 15px;
            display: flex;
            gap: 15px;
            i{
                position: relative;
                top: 1px;
                font-size: 24px;
                @include lg-screen{
                    font-size: 20px;
                }
            }
        }
    }
}

/* ============= Price Plan e  End Here ======================= */
