
/* ====================== About us section Start ==================== */ 
.about {
    position: relative;
    &__thumb{
        position: absolute;
        right: 0px;
        bottom: 100px;
        width: 50%;
        height: 60%;
        display: flex;
        justify-content: end;
        @include md-screen{
            display: none;
        }
    }
    &-item{
        margin-bottom: 40px;
        &__icon{
            margin-bottom: 20px;
            img{
                transition: 1s ease-out 0s;
            }
        }
        &__title{
            margin-bottom: 20px;
        }
        &:hover{
            .about-item__icon img{
                transform: rotateY(360deg);
            }
        }
    }
}

/* ====================== About us section End ==================== */ 
