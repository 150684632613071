/* ================================= Badge Css Start =========================== */
.badge {
    @extend .fs-16; 
    font-weight: 600;
    position: relative;
    padding: 0;
    margin: 0;
    text-align: center;
    &--base {       
        color: hsl(var(--base)) !important;
    }
    &--primary {
        color: hsl(var(--primary)) !important;
    }
    &--secondary {
        color: hsl(var(--secondary)) !important;
    }
    &--success {
        color: hsl(var(--success)) !important;
    }
    &--danger {
        color: hsl(var(--danger)) !important;
    }
    &--warning {
        color: hsl(var(--warning)) !important;
    }
    &--info { 
        color: hsl(var(--info)) !important;
    }
}
/* ================================= Badge Css End =========================== */