
/* ============= Header Start Here ======================= */
.navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    &.logo{
        img {
            max-width: 200px;
            max-height: 60px;
            @include md-screen {
                max-width: 150px;
            }
        }
    }
}

.header {
    position: relative;
    z-index: 5;
    background-color: hsl(var(--white));
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.04);
    @include lg-screen {
        background-color: hsl(var(--white));
        padding: 10px 0;       
        z-index: 999;
        max-height: 101vh;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 0px;
        }
    }
    &.fixed-header {
        background-color: hsl(var(--white));
        position: sticky;
        transition: .3s linear;
        top: 0px;
        animation: slide-down .8s;
        width: 100%;
    }

    .header-login {
        margin: 0px -5px;
        padding-left: 65px;
        display: flex;
        align-items: center;
        height: 100%;
        &__item {
            padding: 0 8px;
            font-size: 1.125rem;
        }
        @include xl-screen{
            padding-left: 30px;
        }
    }
}

@keyframes slide-down {
    0% {
        opacity: 0;
        transform: translateY( -150%);
    } 
    100% {
        opacity: 1;
        transform: translateY(0);
    } 
}

.navbar {
    padding: 0 !important;
}
/* ========================= Desktop Device Start ========================= */
@media (min-width: 1199px) {
.nav-menu {
    padding-top: 0;
    padding-bottom: 0;
    .nav-item{
        position: relative;
        padding-right: 30px;
        &:last-child {
            padding-right: 0;
        }
        &.active {
            .nav-link {
                color: hsl(var(--base)) !important;
                &::before {
                    width: 100%;
                }
            }
        }
        &:hover {
            .nav-link {
                color: hsl(var(--base)) !important;
                &::before {
                    width: 100%;
                }
                .nav-item__icon {
                    transform: rotate(180deg);
                    transition: .2s;
                }
            }
        }
        .nav-link {
            font-weight: 600;
            @include fs-18; 
            color: hsl(var(--black)) !important;
            padding: 33px 0;
            position: relative;
            cursor: pointer;
            /* ======================== Style two ================ */
            &:hover::before {
                left: 0;
                transition: .3s;
            }
            &::before {
                position: absolute;
                content: "";
                right: 0;
                bottom: 0px;
                width: 0;
                height: 4px;
                border-radius: 2px 2px 0px 0px;
                background-color: hsl(var(--heading-color)); 
                // transform: translateX(-50%);
                transition: .3s;
            }
            .nav-item__icon{
                transition: .3s;
                @include fs-13; 
                margin-left: 2px;
                @include md-screen {
                    margin-right: 6px;
                }
            }
        }
    }
}


@media (min-width: 1199px) and (max-width: 1399px){
    .nav-menu {
        .nav-item{
            padding-right: 20px;
            .nav-link {
                @include fs-16; 
            }
        }
    }
}
 .header .dropdown-menu {
    display: block;
    visibility: hidden;
    opacity: 0;
    transition: .3s;
    top: 100%;
    left: 0;
    padding: 0 !important;
    transform: scaleY(0);
    transform-origin: top center;
    transition: .3s;
    overflow: hidden;
    border-radius: 0;
    min-width: 190px;
    &__list {
        border-bottom: 1px solid hsl(var(--black) / .08);
        &:last-child {
            border-bottom: 3px solid hsl(var(--base)); 
        }
    }
    &__link{
        padding: 7px 20px;
        font-weight: 500;
        @include fs-16; 
        transition: .3s;
        &:focus, &:hover {
            color: hsl(var(--white));
            background-color: hsl(var(--base)); 

        } 
    }
}
.nav-menu {
    .nav-item {
        &:hover { 
         .dropdown-menu{
                visibility: visible; 
                opacity: 1;
                top: 100% !important;
                transform: scaleY(1);
            }
        }
    }
}
}
/* ========================== Desktop Device End ========================= */

/* ============================== Small Device ======================= */
@include lg-screen {
    .body-overlay.show {
        visibility: visible;
        opacity: 1;
    }
    .nav-menu {
        margin-top: 20px;
        .nav-item {
            text-align: left;
            display: block;
            position: relative;
            margin: 0;
            &:hover .nav-link .nav-item__icon {
                transform: rotate(0deg) !important;
            }
        }
    }
    .nav-item {
        &:first-child{
            border-bottom: none;
        }
        &:last-child > a{
            border-bottom: 0;
        }
        .nav-link {
            margin-bottom: 8px;
            padding: 10px 10px 10px 0 !important;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 !important;
            border-bottom: 1px solid hsl(var(--light) / .2);
            color: hsl(var(--black));
            &::before{
                display: none;
            }
            &.show {
                &[aria-expanded="true"] {
                    color: hsl(var(--base)) !important;
                    i{
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
   .header .dropdown-menu {
        box-shadow: none;
        border-radius: 2px;
        width: 100%;
        margin: 0px !important;
        padding: 0 !important;
        border: 0;
        background-color: inherit;
        overflow: hidden;
        li{
              &:nth-last-child(1){
                border-bottom: none; 
            }
            .dropdown-item{
                padding: 10px 0px;
                font-weight: 500;
                @include fs-16; 
                color: hsl(var(--white));
                border-bottom: 1px solid hsl(var(--light)/0.2);
                margin-left: 20px;
                color: hsl(var(--heading-color));
                &:hover, &:focus {
                    background-color: transparent;
                }
            }
        }
    } 
}

.navbar-toggler {
    &.header-button {
        border-color: transparent;
        color: hsl(var(--light));
        background: transparent !important;
        padding: 0 !important;
        border: 0 !important;
        border-radius: 0 !important; 
        transition: .15s ease-in-out;
        width: auto;
        font-size: 2.5rem;

        &:focus {
        box-shadow: none !important;
        }
        &[aria-expanded="true"] i::before{
            content: "\f00d";
        }
    }
}

/* Custom Dropdown Css Start */


.custom--dropdown.open > .custom--dropdown__selected::before {
    transform: rotate(180deg);
}

.custom--dropdown > .custom--dropdown__selected {
    background-color: transparent;
    position: relative;
    cursor: pointer;
    padding: 10px;
    font-size: 18px;
    padding-right: 30px;
}

.custom--dropdown > .custom--dropdown__selected::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    content: "\f107";
    display: inline-block;
    position: absolute;
    margin-left: auto;
    width: unset !important;
    right: 15px;
    color: hsl(var(--black));
    font-size: 18px;
}

@media screen and (max-width: 1399px) {
    .custom--dropdown > .custom--dropdown__selected::before {
        right: 2px;
    }
}

@media screen and (max-width: 1399px) {
    .custom--dropdown > .custom--dropdown__selected {
        padding-right: 15px;
    }
}

.custom--dropdown > .dropdown-list {
    position: absolute;
    background-color: hsl(var(--white));
    width: 100%;
    border-radius: 3px;
    -webkit-box-shadow: 0px 12px 24px rgba(21, 18, 51, 0.13);
    box-shadow: 0px 12px 24px rgba(21, 18, 51, 0.13);
    opacity: 0;
    overflow: hidden;
    transition: 0.25s ease-in-out;
    transform: scaleY(0);
    -webkit-transform-origin: top center;
    transform-origin: top center;
    top: 100%;
    z-index: -1;
    visibility: hidden;
    max-height: 230px;
    overflow-y: auto !important;
}

@media screen and (max-width: 991px) {
    .custom--dropdown > .dropdown-list {
        left: auto;
        left: 0;
    }
}

.custom--dropdown > .dropdown-list::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.custom--dropdown > .dropdown-list::-webkit-scrollbar-thumb {
    background-color: hsl(var(--black)/0.15);
}

.custom--dropdown > .dropdown-list::-webkit-scrollbar-thumb {
    background-color: hsl(var(--black)/0.3);
}

.custom--dropdown.open > .dropdown-list {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    visibility: visible;
    z-index: 999 !important;
}

.dropdown-list > .dropdown-list__item {
    padding: 10px 8px !important;
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    font-size: 14px;
}

.dropdown-list > .dropdown-list__item:hover {
    background-color: hsl(var(--base)/0.8) !important;
}

.dropdown-list > .dropdown-list__item, .custom--dropdown > .custom--dropdown__selected {
    display: flex;
    align-items: center;
}

.dropdown-list > .dropdown-list__item .thumb img, .custom--dropdown > .custom--dropdown__selected .thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
}

.dropdown-list > .dropdown-list__item .thumb, .custom--dropdown > .custom--dropdown__selected .thumb {
    width: 35px;
    height: 24px;
    overflow: hidden;
    margin-right: 5px;
    border-radius: 5px;
}

.dropdown-list > .dropdown-list__item .text, .custom--dropdown > .custom--dropdown__selected .text {
    width: calc(100% - 30px);
    padding-left: 4px;
    color: hsl(var(--heading-color));
}

.custom--dropdown{
    position: relative;
    width: auto;
    min-width: 100px;
}

/* ================================= Header Css End =========================== */
