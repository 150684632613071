
/* ================== Sidebar Box & Title =================== */ 
.blog-sidebar {
    background-color: hsl(var(--section-bg)); 
    padding: 30px 20px;
    border-radius: 5px;
    margin-bottom: 30px;
    &:last-of-type {
        margin-bottom: 0;
    }
    @include msm-screen {
        padding: 20px 15px;
    }
    &__title {
        position: relative;
        padding-bottom: 10px;
        color: hsl(var(--black)); 
        &::before {
            position: absolute;
            content: "";
            width: 60px;
            height: 2px;
            background-color: hsl(var(--base)); 
            left: 0;
            bottom: 0px;
        }
    }
}
/* ========================= Category & Tags List Style ========================= */
.text-list {
    /* Category */
    font-weight: 600;
    &.style-category {
        .text-list__item {
            border-bottom: 1px dashed hsl(var(--black) / .2);
            &:last-child {
                border-bottom: 0;
            }
        }
        .text-list__link{
            color: hsl(var(--black)); 
            &:hover {
                color: hsl(var(--base)); 
            }
        }
    }
    /* Style Tag */
    &.style-tag {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: -7px;
        .text-list__item {
           padding: 7px;
        }
        .text-list__link{
            color: hsl(var(--black)); 
            border: 1px solid hsl(var(--black) / .08); 
            padding: 6px 20px;
            border-radius: 3px;
            &.active {
                background-color: hsl(var(--base)); 
                border-color: hsl(var(--base)); 
                color: hsl(var(--white));
            }
            &:hover {
                background-color: hsl(var(--base)); 
                border-color: hsl(var(--base)); 
                color: hsl(var(--white));
            }
        }

    }
}
/* ======================== Latest Blog Css========================  */
.latest-blog {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid hsl(var(--black)/.1);
    &:last-of-type {
        padding-bottom: 0px;
        margin-bottom: 0px;
        border-bottom: 0;
    }
    &__thumb {
        width: 80px;
        max-height: 80px;
        display: flex;
        border-radius: 5px;
        overflow: hidden;
        @include xsm-screen {
            width: 60px;
        }
        a {
            display: block;
            height: 100%;
            width: 100%;
        }
    }
    &__content {
        width: calc(100% - 80px);
        padding-left: 15px;
        @include xsm-screen {
            width: calc(100% - 60px);
        }
    }
    &__title {
        margin-bottom: 5px;
        a {
            color: hsl(var(--heading-color));
            font-weight: 600;
            @extend .fs-16;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            &:hover {
                color: hsl(var(--base)); 
            }
        }
    }
}
/* ========================= Latest Blog Css End ===================*/
