
/* ================================= Card Css Start =========================== */
.custom--card {
    border-radius: 8px;
    background-color: hsl(var(--white));;
    border: 1.5px solid hsl(var(--border-color));
    .card-header {
        padding: 12px 24px;
        background-color: transparent;
        border-bottom: 1px solid hsl(var(--border-color));
        .title {
            margin-bottom: 0;
        }
    }
    .card-body {
        background-color: hsl(var(--white));
        padding: 24px;
        border-radius: 5px;
    }
    .card-footer {
        padding: 12px 24px;
        background-color: transparent;
        border-top: 1px solid hsl(var(--border-color));
    }
}
/* ================================= Card Css End =========================== */

