
/* ================================= Social Icon Css Start =========================== */
.social-list {
    display: flex;
    flex-wrap: wrap; 
    align-items: center;
    &__item {
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
    }
    &__link {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: relative;
        overflow: hidden;
        transition: .3s;
        cursor: pointer;
        color: hsl(var(--white));
        background-color: hsl(var(--white)/.15);
        &.active, &:hover, &:focus  {
            background-color: hsl(var(--base));
            color: hsl(var(--white)) !important;
            border-color: hsl(var(--base)) !important;
        }
        @include sm-screen {
            width: 35px;
            height: 35px;
            @include fs-14;
        }
    }
}
/* ================================= Social Icon Css End ===========================  */