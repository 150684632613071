/* ================================ Testimonails Section Css Start ============================= */
.testimonials {
    position: relative;
    overflow: hidden;
}
.testimonails-card{
    padding-bottom: 20px;
}
.testimonial-item {
    background-color: hsl(var(--section-bg));  
    padding: 32px;
    border-radius: 8px;
    position: relative;
    transition: 0.3s;
    &:hover{
        box-shadow: var(--box-shadow);
    }
    @include xsm-screen {
        padding: 25px 15px;
    }
    &__info {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 18px;
    }
    &__thumb {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
        @include xsm-screen {
            width: 45px;
            height: 45px;
        }
    }
    &__details {
        width: calc(100% - 60px);
        padding-left: 20px;
        @include xsm-screen {
            width: calc(100% - 45px);
            padding-left: 10px;
        }
    }
    &__name {
        margin-bottom: 0;
        color: hsl(var(--black));
    }
    &__designation {
        color: hsl(var(--body-color));
        font-size: 1rem;
    }
    &__desc {
        margin-top: 15px;
        color: hsl(var(--body-color));
        @include xsm-screen {
            margin-top: 10px;
            padding-top: 10px;
        }
    }
}



 [dir='rtl'] .slick-slide {
    float: left !important;

    .testimonial-item {
        text-align: left;
    }
    
    .rating-list {
        justify-content: end;
    }
    .testimonial-item__info {
        flex-direction: row-reverse;
    }
    
}



/* ================================ Testimonails Section Css End ============================= */