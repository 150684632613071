/* =========================================== contact Css Start =========================*/
.contact-content{
    &__list{
        li{
            margin-bottom: 20px;
            font-size: 18px;
            color: hsl(var(--heading-color));
            &:last-child{
                margin-bottom: 0px;
            }
            a{
                color: hsl(var(--heading-color));
                transition: 0.3s;
                &:hover{
                    color: hsl(var(--base));
                }
            }
            img{
                margin-right: 10px;
            }
        }
    }
}

.faq-content{
    &__bottom{
        h5{
            color: hsl(var(--body-color));
            font-family: var(--body-font);
            font-weight: 700;
        }
        a{
            text-decoration: underline;
            font-size: 18px;
        }
    }
}
/* =========================================== contact Css End =========================*/
