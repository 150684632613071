/* ====================== Features us section Start ==================== */ 
.features{
    .features-item{
        padding: 40px 22px;
        border-radius: 8px;
        transition: 0.3s;
        &:hover{
            box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.03), 0px 12px 16px -4px rgba(0, 0, 0, 0.08);
        }
        @include msm-screen{
            text-align: center;
        }
        &__icon{
            margin-bottom: 40px;
            width: 60px;
            height: 60px;
            @include msm-screen{
                margin: 0 auto;
                margin-bottom: 20px;
            }
            img{
                transition: all 1s ease-out 0s;
            }
        }
        &__title{
            margin-bottom: 12px;
        }
        &:hover{
            .features-item__icon img{
                transform: rotateY(360deg);
            }
        }
    }  
}
.section-btn{
    @include sm-screen{
        margin-bottom: 30px;
    }
}
/* ====================== Features us section End ==================== */ 

