/* =========================== Banner Section Start Here ========================= */
.banner-section {
    z-index: 1;
    padding: 180px 0 180px;
    position: relative;
    overflow: hidden;
    @include lg-screen {
        padding: 130px 0px 130px;
    }

    @include md-screen {
        padding: 60px 0 60px;
    }
}

.banner-content {
    &__subtitle {
        color: hsl(var(--heading-color));
        font-size: 1.25rem;
        margin-bottom: 20px;
        @include msm-screen{
            font-size: 1rem;
        }
        img{
            @include msm-screen{
                width: 25px;
            }
        }
        @include msm-screen{
            margin-bottom: 15px;
        }
    }
    &__title {
        color: hsl(var(--heading-color));
        font-size: 4.5rem;
        margin-bottom: 25px;
        @include xxl-screen {
            font-size: 2.813rem;
        }
        @include sm-screen{
            font-size: 2rem;            
        }
        @include msm-screen{
            margin-bottom: 15px;
        }
    }
    &__desc {
        max-width: 600px;
        color: hsl(var(--heading-color) / .8);
        font-size: 1.25rem;
        margin-bottom: 30px;
        @include sm-screen{
            font-size: 1rem;
        }
        @include msm-screen{
            margin-bottom: 15px;
        }
    }
    &__button {
        margin-top: 25px;
    }
    &-thumb {
        position: absolute;
        right: 0px;
        max-width: 830px;
        bottom: 80px;
        @include xxl-screen {
            width: 50%;
        }
        @include md-screen {
            position: relative;
            right: -16%;
            width: 100%;
            bottom: 0;
        }
    }
    &__client {
        display: flex;
        margin-top: 80px;
        gap: 20px;
        align-items: center;

        @include xxl-screen {
            margin-top: 50px;
        }
        @include msm-screen {
            margin-top: 30px;
            gap: 9px;
        }
        .thumb {
            max-width: 270px;
            @include msm-screen {
                width: 100px;
            }
        }
        .number {
            p {
                span {
                    font-size: 2rem;
                    color: hsl(var(--base-two));
                    font-weight: 600;
                    @include msm-screen{
                        font-size: 1.25rem;
                    }
                }
            }
        }
    }
}

@media (min-width: 900px) and (max-width: 991px){
    .banner-content-thumb{
        right: -21%;
    }
}

@media (min-width: 680px) and (max-width: 767px){
    .banner-content-thumb{
        right: -21%;
    }
}


/* =========================== Banner Section End Here ========================= */