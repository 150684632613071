/* =========================== Accordion Css start ============================= */ 
.custom--accordion {
    .accordion-item {
        border: none;
        background-color: transparent !important;
        border-radius: 8px;
        overflow: hidden;
        &:not(:last-child) {
            margin-bottom:20px;
        }
    }
    .accordion-header {
        line-height: 1;
        font-family: var(--body-font);
        font-weight: 600;
        font-size: 18px;
    }
    .accordion-body {
        padding: 20px;
        background-color: hsl(var(--section-bg));
        border-top: 1px solid hsl(var(--border-color) / 0.7);
        .text {
            max-width: 60%;
            @include msm-screen {
                max-width: 100%;
            }
        }
    }
    &:first-of-type .accordion-button.collapsed {
        border-radius: 8px;  
    }
    &:last-of-type .accordion-button.collapsed {
        border-radius: 8px;  
    }
    .accordion-button{
        background-color: hsl(var(--section-bg));
        color: var(--heading-color);
        @extend .fs-18; 
        padding: 25px 24px ;
        @include msm-screen {
            padding: 13px;
            padding-right: 30px;
        }
        &::after {
            background-image: none;
        }
        &:focus {
            box-shadow: none;
        }
        &:not(.collapsed) {
            color: hsl(var(--heading-color)); 
            background-color: hsl(var(--section-bg)) !important;            
            box-shadow: none;
            &::after {
                background-image: none;
                color: hsl(var(--heading-color)); ; 
            }
        }
        &[aria-expanded="true"], &[aria-expanded="false"] {
            &::after {
                font-family: "Line Awesome Free";
                font-weight: 900;
                content: "\f068";
                display: inline-block;
                position: absolute;
                @extend .top-center-extend; 
                right: 15px;
                height: unset;
            }
        }
        &[aria-expanded="false"] {
            &::after {
                content: "\f067";
                color: hsl(var(--body-color));
            }
        }
    }
}
/* ================================= Accordion Css End =========================== */