
/* ================================= Comment Css Start =========================== */
.comment-list {
    &__item {
        &:last-child .comment-list__content {
            border-bottom: none; 
          }
    }
    &__thumb {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        @include sm-screen {
            width: 60px;
            height: 60px;
        }
    }
    &__content {
        width: calc(100% - 80px);
        padding-left: 15px;
        border-bottom: 1px solid hsl(var(--black) / .1);
        padding-bottom: 30px;
        margin-bottom: 30px;
        @include sm-screen {
            width: calc(100% - 60px);
            padding-bottom: 25px;
            margin-bottom: 25px;
        }
        @include msm-screen {
            padding-bottom: 20px;
            margin-bottom: 20px;
            width: 100%;
            padding-left: 0;
            margin-top: 15px;
        }
    }
    &__name {
        margin-bottom: 5px;
        color: hsl(var(--black));
    }
    &__time {
        margin-bottom: 15px;
        &-icon {
            color: hsl(var(--base));
            @extend .fs-15; 
            margin-right: 5px;
        }
    }
    &__reply {
        margin-top: 10px;
        display: inline-block;
        &-text {
            color: hsl(var(--black));
            font-weight: 400;
        }
        &:hover &-icon {
            color: hsl(var(--base));
        }
        &-icon {
            @extend .fs-14; 
            transform: scaleX(-1);
            margin-left: 5px;
            @include sm-screen {
                @include fs-12;
            }
        }
    }

    // Second List Item
    .comment-list {
        margin-left: 70px;
        &.style-right {
            .comment-list__item {
                &:last-child .comment-list__content {
                    border-bottom: 1px solid hsl(var(--black) / .1);
                  }
            }
        }
        @include msm-screen {
            margin-left: 40px;
        }
    }
} 
/* ================================= Comment Css End =========================== */ 
