
/* ================================= Common Typography Css Start =========================== */
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    font-family: var(--body-font);
    color: hsl(var(--body-color));
    word-break: break-word;
    background-color: hsl(var(--white));
    min-height: 100vh;
    display: flex;
    flex-direction: column;
   
}
p {
    @extend .fs-18; 
    font-weight: 400;
    margin: 0;
}
span {
  display: inline-block;
}

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 20px 0;
    font-family: var(--heading-font);
    color: hsl(var(--heading-color));
    line-height: 1.3;
    font-weight: 600;
    @include sm-screen {
      margin: 0 0 15px 0;
    }
  }
  h1 {
    font-size: clamp(1.875rem, 2.8vw + 1rem, 3.75rem); //Min:30px - Max:60px
  }
  h2 {
    font-size: clamp(1.5625rem, 1.7vw + 1rem, 2.5rem); //Min: 25px - Max: 40px
  }
  h3 {
    font-size: clamp(1.375rem, .8vw + 1rem, 1.875rem); //Min: 22px - Max: 30px
  }
  h4 {
    font-size: clamp(1.25rem, .5vw + 1rem, 1.5rem); //in: 20px - Max: 24px
  }
  h5 {
    font-size: clamp(1.0625rem, .3vw + 1rem, 1.25rem); //in: 17px - Max: 20px
  }
  h6 {
    font-size: 1rem;
  }
  h1 > a,
  h2 > a,
  h3 > a,
  h4 > a,
  h5 > a,
  h6 > a {
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
    transition: .2s linear;
    line-height: 1.3;
  }

a {
    display: inline-block;
    transition: .2s linear;
    text-decoration: none;
    color: #0667f6;
}
a:hover {
  color: #2f83ff;
}
img {
    max-width: 100%;
    height: auto;
}
select {
  cursor: pointer;
}
ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}
button {
  border: 0;
  background-color: transparent;
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
.form-select:focus {
  outline: 0;
  box-shadow: none;
}
/* ================================= Common Typography Css End =========================== */