/* =========================================== Account Css Start =========================*/

.account-form  {
    position: relative;
    background-color: hsl(var(--section-bg));
    padding: 70px 80px;
    border-radius: 8px;
    overflow: hidden;
    z-index: 1;
   &:before {
        position: absolute;
        top: 0px;
        left: 0px;
        content: "";
        width: 123px;
        height: 352px;
        background-color: #3100F5;
        filter: blur(188px);
        border-radius: 50%;
        z-index: -1;
        opacity: 0.4;
    }
    &:after {
        position: absolute;
        bottom: 0px;
        right: 0px;
        content: "";
        width: 123px;
        height: 300px;
        background-color: #F59300;
        filter: blur(188px);
        border-radius: 50%;
        z-index: -1;
        opacity: 0.4;
    }
    @include lg-screen {
        padding: 40px 30px;
    }
    @include md-screen {
        padding: 35px 25px;
    }
    @include sm-screen {
        padding: 30px 20px;
    }
    @include xsm-screen {
        padding: 30px 15px;
    }
}

.signup-btn{
    img{
        width: 16px;
        height: 16px;
    }
}
/* ============================================ Account Css End ====================================*/