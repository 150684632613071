/* ================================= Color Css Start =========================== */
.text {
    &--base {
        color: hsl(var(--base)) !important;
    }
    &--primary {
        color: hsl(var(--primary)) !important;;
    }
    &--secondary {
        color: hsl(var(--secondary)) !important;;
    }
    &--success {
        color: hsl(var(--success)) !important;;
    }
    &--danger {
        color: hsl(var(--danger)) !important;;
    }
    &--warning {
        color: hsl(var(--warning)) !important;;
    }
    &--info {
        color: hsl(var(--info)) !important;;
    }
    &--dark {
        color: hsl(var(--black)) !important;;
    }
    &--green{
        color: hsl(var(--success));
    }
    &--yellow{
        color: #F0F500;
    }
    &--base-two{
        color: hsl(var(--base-two));
    }
    &--base-three{
        color: #F50000;
    }
    &--green-two{
        color: #00F50A;
    }
    &--primary-two{
        color: #000AF5;
    }
    &--orange{
        color: #F55800;
    }
    &--body-two{
       color: hsl(var(--body-color)/0.7);
    }
}
/* ================================= Color Css End =========================== */