
/* ===================== Scroll to Top Start ================================= */
.scroll-top {
    position: fixed;
    right: 30px;
    bottom: 30px;
    color: hsl(var(--white));
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    z-index: 5;
    transition: 0.5s;
    cursor: pointer;
    transform: scale(0);
    background-color: hsl(var(--base));
    &:hover {
        color: hsl(var(--white));
        background-color: hsl(var(--base-d-100));
    }
    &.show{
        transform: scale(1);
    }
}
/* ===================== Scroll to Top End ================================= */
