/* ================================= Start Rating Css Start =========================== */
.rating-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    &__item {
        padding: 0 1px;
        color: #F5CE00;
    }
    &__text {
        color: hsl(var(--body-color));
    }
}
/* ================================= Start Rating Css End =========================== */