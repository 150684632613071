/* ================================= Counter Css Start =========================== */

.counter-area {
    position: relative;
    overflow: hidden;

    &:before {
        position: absolute;
        top: 0px;
        left: 0px;
        content: "";
        width: 80px;
        height: 80px;
        background-color: #1400F5;
        filter: blur(90px);
        z-index: 1;
        border-radius: 50%;
    }

    &:after {
        position: absolute;
        bottom: 0px;
        right: 0px;
        content: "";
        width: 80px;
        height: 80px;
        background-color: #B500F5;
        filter: blur(90px);
        z-index: 1;
        border-radius: 50%;
    }
}
.counterup-item{
    @include md-screen{
        text-align: center;
    }
    &__text{
        font-size: 20px;
        font-weight: 700;
        color: hsl(var(--heading-color));
        @include lg-screen{
            font-size: 18px;
        }
    }
}

/* ================================= Counter Css End =========================== */