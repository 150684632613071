/* ================================= Modal Css Start =========================== */
.custom--modal {
    .modal-header {
        border-bottom: 1px solid hsl(var(--border-color));
        padding: 15px;
        &.close {
            width: 35px;
            height: 35px;
            background-color: hsl(var(--danger)) !important;
            font-size: 1.5625rem; // 25px
            line-height: 1;
            border-radius: 4px;
            transition: 0.2s linear;
            &:hover {
                background-color: hsl(var(--danger-l-100));
            }
            :focus {
                box-shadow: none !important;
              }
        } 
    }
    .modal-content {
        background-color: hsl(var(--white));
        border-radius: 10px !important;
    }
   
    .modal-body {
        padding: 15px;
    }
    .modal-icon i {
        font-size: 2rem; // 32px
        color: hsl(var(--base));
        border: 3px solid hsl(var(--base));
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
    .modal-footer {
        display: flex;
        flex-wrap: wrap;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        padding: 15px;
        border-top: 1px solid hsl(var(--border-color));
        justify-content: flex-end;
    }
}
/* ================================= Modal Css End =========================== */

