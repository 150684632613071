
/* =========================================== Blog Details Css Start ==============================*/
.blog-details {
    border-radius: 8px;
    overflow: hidden;
    height: 100%;
    box-shadow: 0px 2px 15px hsl(var(--white) /.25);
    &__thumb {
        height: 500px;
        max-height: 100%;
        @include md-screen {
            height: 350px;
        }
        @include sm-screen {
            height: 300px;
        }
    }
    &__content {
        padding: 35px 25px;
        @include sm-screen {
            padding: 30px 20px;
        }
        @include msm-screen {
            padding: 25px 15px;
        }
    }
    &__title {
        margin-top: 20px;
        margin-bottom: 15px;
    }
    &__desc {
        margin-bottom: 15px;
    }
    .social-list {
        &__link {
            border: 1px solid hsl(var(--black)/0.1);
            color: hsl(var(--body-color));
            &:hover {
                border-color: hsl(var(--base));
                color: hsl(var(--white));
            }
        }
    }
}
.quote-text {
    background-color: hsl(var(--base) / .1);
    padding: 30px 20px;
    border-radius: 5px;
    border-left: 3px solid hsl(var(--base));
    margin-bottom: 20px;
    @include sm-screen {
        padding: 25px 15px;
    }
}
/* ========================================== Blog Details Css End ======================================*/