/* ================================= Table Css Start =========================== */
/* Table Content Css start */

.action-buttons {
	@extend .flex-align; 
	gap: 10px;
	justify-content: flex-end;
}
.action-btn {
	width: 35px;
	height: 35px;
	border-radius: 50%;
}

.delete-btn {
	color: hsl(var(--danger));
	background-color: hsl(var(--danger) / .08); 
}
/* Table Content Css end */

/* Table Css Start */
.transform--top i {
    transform: rotate(315deg);
}
.transform--bottom i {
    transform: rotate(50deg);
}
.dashboard-table{
	background-color: hsl(var(--white));
	border-radius: 8px;
	border: 1.5px solid hsl(var(--border-color));
	&__header{
		padding: 30px;
		display: flex;
		align-items: center;
		gap: 15px;
		@include msm-screen{
			display: block !important;
			padding: 20px;
		}
	}
	&__content{
		width: calc(100% - 200px);
		@include msm-screen{
			width: 100%;
		}
		.title{
			font-size: 1rem;
			margin-bottom: 5px;
		}
		.subtitle{
			font-size: 12px;
			font-weight: 600;
			 span {
				width: 10px;
				height: 10px;
				background: hsl(var(--green-two));
				border-radius: 10px;
				margin-right: 5px;
			}
		}
	}
	&__btn{
		width: 200px;
		text-align: end;
		@include msm-screen{
			margin-top: 20px;
			width: 100%;
		}
	}

}


.table {
	margin: 0;
	border-collapse: collapse;
	border-collapse: separate;
	border-spacing: 0px 0px;
	thead { 
		tr {
			th {
				background-color: hsl(var(--white)); 
				padding: 15px 20px;
				color: hsl(var(--base)); 
				font-family: var(--body-font);
				font-weight: 600;
                border-bottom: 0;
				max-width: 170px;
				@extend .fs-14; 
				border: none;
				border-right: none;
				background-color: hsl(var(--base)/ 0.04);
				@include xxxl-screen{
					padding: 15px 10px;
				}
				&:not(:first-child) {
					border-left: 0;
				}
				&:first-child {
					text-align: left;
				}
				&:last-child{
					text-align: right;
				}
				&:nth-child(2) {
					text-align: left;
				}
			}
		}
	}
	tbody {
		border: 0 !important;
        background-color: hsl(var(--white)); 
		tr {
			border-bottom: 1px solid hsl(var(--border-color));
			&:last-child {
				border-bottom: 0;
			}
			&:last-child {
				td {
					border-bottom: none !important;
					&:first-child {
						 border-radius: 0px 0 0 6px;
					}
					&:last-child {
						border-radius: 0 0px 6px 0;
					}
				}
			}
			td {
				vertical-align: middle;
				padding: 12px 24px;
				border-width: 1px;
                border: 0;
				font-family: var(--body-font);
				color: hsl(var(--heading-color)); 
				font-weight: 600;
				max-width: 170px;
				@extend .fs-16; 
				border-bottom: 1px solid hsl(var(--border-color));
				border-right: 1px solid hsl(var(--border-color));
				@include xxxl-screen{
					padding: 12px 12px;
				}
				&::before {
					content: attr(data-label);
					font-family: var(--body-font);
					@extend .fs-15; 
					color: hsl(var(--black)); 
					font-weight: 600;
					display: none;
                    width: 45% !important;
                    text-align: left;
				}
				&:first-child {
					text-align: left;
					@extend .fs-16; 
				}
				&:last-child {
					text-align: right;
					border-right: none;
				}
				p{
					font-size: 1rem;
				}
			}
		}
	}
}

.table--responsive--md {
    @include sm-screen {
        thead {
			display: none;
		}
		tbody {
			tr {
				display: block;
				&:last-child td {
					border-bottom: 0;
				}
				td {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 15px;
					text-align: right;
					padding: 10px 15px;
					border: none;
					border-bottom: 1px solid hsl(var(--black)/.8);  
					max-width: unset;
					&:last-child {
						border: none;
					}
					&:first-child {
						text-align: right;
						border-left: 0;
					}
					&::before {
					    display: block;
						@include fs-14; 
						color: hsl(var(--black)/0.7); 
					}
				}
			}
		}
    }
	@include sm-screen {
        tbody {
			tr {
				td {
					border: 0;
				}
			}
		}
    }
}
.table--responsive--lg {
    @include md-screen {
        thead {
			display: none;
		}
		tbody {
			tr {
				display: block;
				&:nth-child(even) {
					background-color: hsl(var(--black) / .02);
				}
				&:last-child td {
					border-bottom: 0;
				}
				td {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 15px;
					text-align: right;
					padding: 10px 15px;
					border: none;
					border-bottom: 1px solid hsl(var(--black) / .08);  
					max-width: unset;
					&:last-child {
						border: none;
					}
					&:first-child {
						text-align: right;
						border-left: 0;
					}
					&::before {
					    display: block;
						@include fs-14; 
						color: hsl(var(--black)/0.7); 
					}
				}
			}
		}
    }
    @include md-screen {
        tbody {
			tr {
				td {
					border: none;
				}
			}
		}
    }
}
.table--responsive--xl {
    @include lg-screen {
        thead {
			display: none;
		}
		tbody {
			tr {
				display: block;
				&:nth-child(even) {
					background-color: hsl(var(--black) / .02);
				}
				td {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 15px;
					text-align: right;
					padding: 10px 15px;
					border: none;
					border-bottom: 1px solid hsl(var(--black) / .08);  
					max-width: unset;
					&:last-child {
						border: none;
					}
					&:first-child {
						text-align: right;
						border-left: 0;
					}
					&::before {
					    display: block;
						@include fs-14; 
						color: hsl(var(--black)/0.7); 
					}
				}
			}
		}
    }
    
    @include lg-screen {
        tbody {
			tr {
				td {
					border: 0;
				}
			}
		}
    }
}
.table--responsive--xxl {
    @include xl-screen {
        thead {
			display: none;
		}
		tbody {
			tr {
				display: block;
				&:last-child td {
					border-bottom: 0;
				}
				td {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 15px;
					text-align: right;
					padding: 10px 15px;
					border: none;
					border-bottom: 1px solid hsl(var(--black) / .08);  
					max-width: unset;
					&:last-child {
						border: none;
					}
					&:first-child {
						text-align: right;
						border-left: 0;
					}
					&::before {
					    display: block;
						@include fs-14; 
						color: hsl(var(--black)/0.7); 
					}
				}
			}
		}
    }
    
    @include xl-screen {
        tbody {
			tr {
				td {
					border: 0;
				}
			}
		}
    }
}
/* ================================= Table Css End =========================== */