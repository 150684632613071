/* ================================= Background Color Css Start =========================== */
.bg {
    &--base {
        background-color: hsl(var(--base)) !important;
    }
    &--primary {
        background-color: hsl(var(--primary)) !important;
    }
    &--secondary {
        background-color: hsl(var(--secondary)) !important;
    }
    &--success {
        background-color: hsl(var(--success)) !important;
    }
    &--danger {
        background-color: hsl(var(--danger)) !important;
    }
    &--warning {
        background-color: hsl(var(--warning)) !important;
    }
    &--info {
        background-color: hsl(var(--info)) !important;
    }
    &--base2{
        background-color: hsl(var(--base-2));
    }
}

.bg--green{
    background-color: hsl(var(--green-two));
}
.bg--green-two{
    background-color: #00F50A;
}
.bg--yellow{
    background-color: #F0F500;
}
.bg--base-two{
    background-color: hsl(var(--base-two));
}
/* ================================= Background Color Css End =========================== */