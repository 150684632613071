
/* ====================== Section Heading ==================== */
.section-heading {
  margin-bottom: 50px;
  @include lg-screen {
    margin-bottom: 40px;
  }
  @include md-screen {
    margin-bottom: 30px;
  }
  &__subtitle {
    @include fs-20;
    font-weight: 600;
    margin-bottom: 15px;
    color: hsl(var(--heading-color));
  }
  &__title {
    margin-bottom: 20px;
  }
  &__desc {
    max-width: 750px;
   
  }
  // Style left
  &.style-center {
    max-width: 600px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}


::selection {
  color: hsl(var(--white));
  background: hsl(var(--base-l-200));
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background-color: hsl(var(--base-d-100));
  border: 0px solid transparent;
  border-radius: 2px;
}


/* ====================== Section Heading En d==================== */
