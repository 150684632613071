/* ============= Footer Start Here ======================= */
.footer-area {
    background-color: hsl(var(--section-bg));
    margin-top: auto;
    overflow: hidden;
    position: relative;
    &:before {
        position: absolute;
        top: 0px;
        left: 0px;
        content: "";
        width: 123px;
        height: 350px;
        background-color: #F5B000;
        filter: blur(188px);
        border-radius: 50%;
        z-index: 1;
        @include sm-screen{
            height: 100px;
        }
    }
    &:after{
        position: absolute;
        bottom: 0px;
        right: 0px;
        content: "";
        width: 123px;
        height: 300px;
        background-color: #C400F5;
        filter: blur(188px);
        border-radius: 50%;
        z-index: 1;
        @include sm-screen{
            height: 100px;
        }
    }
}

.footer-item {
    position: relative;
    z-index: 2;
    &__logo {
        margin-bottom: 55px;

        a {
            img {
                width: 100%;
                height: 100%;
                max-width: 190px;
                max-height: 64px;
            }
        }
    }

    &__title {
        color: hsl(var(--heading-color));
        padding-bottom: 10px;
        margin-bottom: 10px;
        font-size: 1.125rem;
    }

    .social-list {
        margin-top: 30px;

        @include md-screen {
            margin-top: 20px;
        }

        @include msm-screen {
            margin-top: 15px;
        }
    }
    

    &__subscribe{
        @include sm-screen{
            .input-group{
                display: block;
            }
        }
        input{
            margin-right: 15px;
            border-radius: 8px !important;
            @include sm-screen{
                width: 100% !important;
            }
        }
        .btn{
            border-radius: 8px !important;
            @include sm-screen{
                width: 100% !important;
                margin-top: 10px;
            }
        }
    }
}

.underline-with-text{
    color: hsl(var(--heading-color));
    text-decoration: underline !important;
    font-weight: 600;
    
}
/* Footer List Item */
.footer-menu {
    display: flex;
    flex-direction: column;
    &__item {
        display: block;
        padding-bottom: 16px;

        &:last-child {
            padding-bottom: 0;
        }
    }

    &__link {
        color: hsl(var(--body-color));

        &:hover {
            color: hsl(var(--base));
            text-decoration: underline;
        }
    }
}

/* Footer Contact */
.footer-contact-menu {
    &__item {
        display: flex;
        padding-bottom: 12px;

        &:last-child {
            padding-bottom: 0;
        }

        &-icon {
            width: 15px;
            color: hsl(var(--base));
            font-size: 1.25rem; // 20px
        }

        &-content {
            width: calc(100% - 15px);
            padding-left: 15px;
        }
    }
}

/* ============= Footer End Here ======================= */

/* ============= Bottom Footer End Here ======================= */
.footer-bottom-inner {
    padding: 35px 0px;
    border-top: 1px solid hsl(var(--light) / .2);
}

.bottom-footer-copyright {
    ul {
        display: flex;
        flex-wrap: wrap;
        @include sm-screen{
            justify-content: center;
        }
        li {
            margin-right: 24px;
            font-size: 0.875rem;
            @include sm-screen{
                margin-right: 10px;
            }
            &:last-child{
                margin-right: 0px;
            }
            a{
                color: hsl(var(--heading-color));
                text-decoration: underline !important;
                font-weight: 600;
                transition: 0.3s;
                &:hover{
                    color: hsl(var(--base));
                }
            }
        }
    }
}

.footer-social{
    text-align: right;
    @include  sm-screen{
        text-align: center;
    }
    a{
        margin-right: 12px;
        &:last-child{
            margin-right: 0px;
        }
    }
}

.footer-item-left{
   max-width: 520px;
}
/* =============Bottom Footer End Here ======================= */